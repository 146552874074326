import _ from 'lodash';

import { BID_ITEM_STATUS } from '@/config/bid';
import { OPS_PORTAL_URL } from '@/services/connections.service';
import * as HttpService from '@/services/http.service';
import { GET_TENDER_RESPONSE_API_URL } from '@/services/url.service';

const getTenderResponseAPI = ({ referenceId }) =>
  HttpService.getWithAuth(GET_TENDER_RESPONSE_API_URL({ referenceId }));

export const getEditPartnerURL = ({ partnerId }) =>
  `${OPS_PORTAL_URL}/partners/${partnerId}/edit/basic-details`;

export const getEditProductURL = ({ productId }) =>
  `${OPS_PORTAL_URL}/products/${productId}/edit/basic-details`;

const getResponsesOfProduct = ({ bids, tenderItems }) => {
  const productsResponseInfo = {};

  tenderItems.forEach((tenderItem) => {
    const { availableWith, entityId, bidIdList, id: tenderItemId } = tenderItem;

    if (!productsResponseInfo[entityId]) {
      productsResponseInfo[entityId] = {
        availableWith: 0,
        productBidInfoList: []
      };
    }

    const productBidInfoListRaw = bidIdList.map((bidId) => {
      const bid = bids.find(({ id }) => bidId === id);
      const bidItemIdList = bid.bidItems
        .filter(({ tenderItemId: id }) => id === tenderItemId)
        .map(({ bidItemId }) => bidItemId);

      return {
        bidId,
        bidItemIdList,
        tenderItemId
      };
    });

    const productBidInfoListList = [];
    productBidInfoListRaw.forEach(
      ({ bidId, bidItemIdList, tenderItemId: id }) => {
        bidItemIdList.forEach((bidItemId) => {
          productBidInfoListList.push({
            bidItemId,
            bidId,
            tenderItemId: id
          });
        });
      }
    );

    productsResponseInfo[entityId].availableWith += availableWith;
    productsResponseInfo[entityId].productBidInfoList.push(
      ...productBidInfoListList
    );

    productsResponseInfo[entityId] = {
      ...productsResponseInfo[entityId],
      ..._.pick(tenderItem, [
        'name',
        'notes',
        'quantityRequested',
        'unitPriceRequested'
      ])
    };
  });

  const detailedProductsResponseList = Object.keys(productsResponseInfo).map(
    (productId) => {
      const { productBidInfoList } = productsResponseInfo[productId];
      const detailedProductBidInfoList = productBidInfoList.map(
        ({ bidId, bidItemId, ...restproductBidInfoList }) => {
          const bid = bids.find(({ id }) => id === bidId);
          const bidItem = bid.bidItems.find(
            ({ bidItemId: id }) => bidItemId === id
          );
          const partnerName = bid.partner.legalName;
          return {
            ...restproductBidInfoList,
            partnerName,
            ..._.pick(bidItem, [
              'partnerNotes',
              'quantityRequested',
              'status',
              'unitPartnerPriceOffered'
            ])
          };
        }
      );

      const receivedFromPartners = detailedProductBidInfoList.filter(
        ({ status }) => status !== BID_ITEM_STATUS.PENDING.value
      ).length;

      return {
        ...productsResponseInfo[productId],
        productBidInfoList: detailedProductBidInfoList,
        productId,
        receivedFromPartners
      };
    }
  );

  return detailedProductsResponseList;
};

const parseTenderResponse = (tenderResponseRaw) => {
  const { tenderItems, bids: bidsRaw, ...tenderResponse } = tenderResponseRaw;

  const tenderItemMap = _.keyBy(tenderItems, 'id');

  const bids = bidsRaw.map(
    ({ bidItems: bidItemsRaw, ...productBidInfoList }) => ({
      ...productBidInfoList,
      bidItems: bidItemsRaw
        .map(({ id: bidItemId, tenderItemId, ...bidItemInfo }) => ({
          ...bidItemInfo,
          ...tenderItemMap[tenderItemId],
          bidItemId
        }))
        .map(({ id: tenderItemId, ...rest }) => ({ ...rest, tenderItemId }))
    })
  );

  const bidResponsesOfItems = getResponsesOfProduct({ bids, tenderItems });

  return {
    ...tenderResponse,
    bids,
    bidResponsesOfItems,
    tenderItemMap
  };
};

export const fetchTenderResponse = async ({
  referenceId,
  setLoading,
  setShowToast,
  setTenderResponse
}) => {
  setLoading(true);

  const { entity, message, status } = await getTenderResponseAPI({
    referenceId
  });
  if (status) {
    const tenderResponse = parseTenderResponse(entity);
    setTenderResponse(tenderResponse);
  }
  !status &&
    setShowToast({
      message: message || 'Failed To Fetch Tender Response',
      show: true,
      status
    });
  setLoading(false);
};

export const getTenderItemAskPrice = ({ bidItem }) => {
  const { quantityRequested, unitPriceRequested } = bidItem;
  return (unitPriceRequested || 0) * quantityRequested;
};

export const getTenderItemOfferPrice = ({ bidItem }) => {
  const { quantityRequested, unitPartnerPriceOffered } = bidItem;
  return unitPartnerPriceOffered * quantityRequested;
};

export const tenderResponseByItemHeaderConfig = [
  { className: 'flex w-112', content: 'Item' },
  { className: 'flex w-40', content: 'Requested Quantity' },
  { className: 'flex w-40', content: 'Total ask Price' },
  { className: 'flex w-48', content: 'Requested from Partners' },
  { className: 'flex w-48', content: 'Received from Partners' }
];

export const bidSubmissionStatusMap = {
  PARTIALLY_SUBMITTED: 'bg-warning-light text-warning-dark',
  PENDING: 'bg-info-light text-info-base',
  SUBMITTED: 'bg-greenlight text-green-base'
};

export const tenderResponseByPartnerHeaderConfig = [
  { className: 'flex w-80', content: 'Partner' },
  { className: 'flex w-24', content: 'Requested' },
  { className: 'flex w-24', content: 'Available' },
  { className: 'flex w-32', content: 'delivery charge' },
  { className: 'flex w-28', content: 'Grand  Total' },
  { className: 'flex w-112', content: 'Comments' },
  { className: 'flex w-40', content: 'Status' }
];

export const tenderItemBidHeaderConfig = [
  { className: 'flex w-60', content: 'Item name' },
  { className: 'flex w-32', content: 'Requested Quantity' },
  { className: 'flex w-24', content: 'Total Ask price ' },
  { className: 'flex w-24', content: 'Availability' },
  { className: 'flex w-28', content: 'Total Offer price ' },
  { className: 'flex w-128', content: 'Comments' }
];

export const tenderItemBidderHeaderConfig = [
  { className: 'flex w-60', content: 'Partner name' },
  { className: 'flex w-32', content: 'Availability' },
  { className: 'flex w-32', content: 'Total Offer price ' },
  { className: 'flex w-150', content: 'Comments' }
];
