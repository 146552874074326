import { LINE_ITEM_SOURCE, PRODUCT_STATUS } from '@/config/common';
import { itemMediaListMapper } from '@/helpers/carousel';
import { convertToDateTimeString } from '@/lib/time';
import * as HttpService from '@/services/http.service';
import {
  DELETE_ORDER_ITEM_MEDIA_API_URL,
  GET_ORDER_DETAILS_API_URL,
  GET_ORDER_INVOICE_API_URL,
  RECONCILE_ORDER_ITEM_MEDIA_FROM_IMAGEKIT_API_URL,
  UPDATE_ORDER_ITEM_MEDIA_SORT_ORDER_API_URL
} from '@/services/url.service';

const environmentName = process.env.NEXT_PUBLIC_APP_ENV;

export const getOrderDetailsAPI = ({ orderId }) =>
  HttpService.getWithAuth(GET_ORDER_DETAILS_API_URL({ orderId }));

export const getOrderInvoiceAPI = ({ orderId }) =>
  HttpService.getWithAuth(GET_ORDER_INVOICE_API_URL({ orderId }));

const deleteOrderItemMediaAPI = ({ orderId, orderItemId, orderItemMediaId }) =>
  HttpService.deleteWithAuth(
    DELETE_ORDER_ITEM_MEDIA_API_URL({ orderId, orderItemId, orderItemMediaId })
  );

export const reconcileOrderItemMediaWithImageKitAPI = ({
  orderId,
  orderItemId
}) =>
  HttpService.postWithAuth(RECONCILE_ORDER_ITEM_MEDIA_FROM_IMAGEKIT_API_URL(), {
    orderId,
    orderItemId
  });

export const updateOrderItemMediaSortOrderAPI = ({
  orderId,
  orderItemId,
  orderItemMediaToSort
}) =>
  HttpService.postWithAuth(
    UPDATE_ORDER_ITEM_MEDIA_SORT_ORDER_API_URL({ orderId, orderItemId }),
    { orderItemMediaToSort }
  );

export const transformOrderItemsForReadOnlyView = ({ orderItems }) =>
  orderItems.map(
    ({
      id,
      orderId,
      orderItemMedia,
      product: { name: productName, productMedia },
      productNotes,
      quantity,
      showProductMedia,
      unitListedPrice,
      unitSellingPrice
    }) => ({
      ...itemMediaListMapper({
        additionalMediaList: orderItemMedia,
        product: { productMedia },
        showProductMedia,
        source: LINE_ITEM_SOURCE.ORDER.value
      }),
      id,
      orderId,
      productName,
      productNotes,
      productSource: LINE_ITEM_SOURCE.ORDER.value,
      productStatus: PRODUCT_STATUS.PUBLISHED,
      quantity,
      unitListedPrice,
      unitSellingPrice
    })
  );

const sortEventDetailsForDisplay = (
  { userEventOrderDetailsBody, ...rest },
  isMobile
) => {
  const sortKey = isMobile ? 'sortOrderMobile' : 'sortOrderWeb';
  return {
    ...rest,
    userEventOrderDetailsBody: Object.values(userEventOrderDetailsBody).sort(
      (a, b) => a[sortKey] - b[sortKey]
    )
  };
};

export const getUserEventDetails = ({ isMobile, orderDetail }) => {
  const {
    deliveryDate,
    deliveryTime,
    pickupDate,
    pickupTime,
    userEventDetail: {
      checkoutEvent: {
        event: { name: checkoutEventName }
      },
      eventAddress,
      eventContactName,
      eventDate,
      eventStartTime,
      opsUser,
      trnNumber
    },
    user: { hostCreditEntity, mobile: eventContactNumber }
  } = orderDetail;
  const { name: hostCreditEntityName = null } = hostCreditEntity || {};
  const trnLabel = trnNumber ? `TRN: ${trnNumber}` : '';

  return sortEventDetailsForDisplay(
    {
      userEventOrderDetailsBanner: {
        eventName: {
          value: checkoutEventName || 'NA',
          sortOrderMobile: null,
          sortOrderWeb: null
        },
        trnNumber: {
          value: trnLabel,
          sortOrderMobile: null,
          sortOrderWeb: null
        }
      },
      userEventOrderDetailsBody: {
        deliveryDate: {
          alt: 'delivery date',
          className: 'hidden md:flex',
          icon: 'delivery-truck.svg',
          sortOrderMobile: 1,
          sortOrderWeb: 4,
          value: convertToDateTimeString({
            date: deliveryDate,
            time: deliveryTime
          })
        },
        hostCreditEntityName: {
          alt: 'event contact name',
          icon: 'host-credit-entity-red-icon.svg',
          sortOrderMobile: 1,
          sortOrderWeb: 1,
          value: hostCreditEntityName || ''
        },
        trnNumber: {
          alt: 'event contact name',
          icon: 'trn-icon-red.svg',
          sortOrderMobile: 2,
          sortOrderWeb: 5,
          value: trnLabel
        },
        eventAddress: {
          alt: 'event address',
          icon: 'location-icon-with-frame.svg',
          sortOrderMobile: 7,
          sortOrderWeb: 7,
          value: eventAddress.formattedAddress
        },
        eventContactName: {
          alt: 'event contact name',
          icon: 'user-red-with-frame.svg',
          sortOrderMobile: 3,
          sortOrderWeb: 2,
          value: eventContactName
        },
        eventContactNumber: {
          alt: 'event contact number',
          icon: 'call-with-frame.svg',
          sortOrderMobile: 4,
          sortOrderWeb: 6,
          value: eventContactNumber
        },
        eventDate: {
          alt: 'event date',
          icon: 'calendar-red-with-frame.svg',
          sortOrderMobile: 5,
          sortOrderWeb: 3,
          value: convertToDateTimeString({
            date: eventDate,
            time: eventStartTime
          })
        },
        pickupDate: {
          alt: 'pickup date',
          className: 'hidden md:flex',
          icon: 'pickup-truck.svg',
          sortOrderMobile: 6,
          sortOrderWeb: 9,
          value: convertToDateTimeString({
            date: pickupDate,
            time: pickupTime
          })
        },
        opsUser: {
          alt: 'planner name',
          className: 'md:hidden',
          icon: 'planner-icon-orange.svg',
          sortOrderMobile: 8,
          sortOrderWeb: 7,
          value: opsUser?.name || ''
        }
      }
    },
    isMobile
  );
};

export const calculatePreVatPrice = ({
  additionalChargeAmount,
  deliveryChargeAmount,
  listedDiscountAmount,
  listedPriceAmount,
  lumpSumDiscount,
  promoCodeDiscountAmount
}) => {
  const discountAmount =
    (lumpSumDiscount || 0) +
    (promoCodeDiscountAmount || 0) +
    listedDiscountAmount;

  return (
    listedPriceAmount +
    additionalChargeAmount +
    deliveryChargeAmount -
    discountAmount
  );
};

export const transformDeliveryDetailForMobile = ({
  deliveryDate,
  deliveryTime = '',
  externalNotes,
  pickupDate,
  pickupTime = ''
}) => ({
  deliveryDate: deliveryDate || '',
  deliveryTime,
  externalNotes,
  pickupDate: pickupDate || '',
  pickupTime
});

export const fetchOrderDetails = async ({
  orderId,
  setLoading,
  setOrderDetail,
  setShowToast
}) => {
  setLoading(true);
  const { message, entity, status } = await getOrderDetailsAPI({ orderId });
  if (status && entity?.orderItems) {
    setOrderDetail(entity);
  }
  !status &&
    setShowToast({
      message,
      show: true,
      status
    });
  setLoading(false);
};

export const onDeleteOrderItemMedia = async ({
  orderId,
  orderItemId,
  orderItemMediaId,
  router,
  setShowToast
}) => {
  const { status, message } = await deleteOrderItemMediaAPI({
    orderId,
    orderItemId,
    orderItemMediaId
  });
  if (status) {
    router.reload();
    return;
  }
  !status && setShowToast({ message, show: true, status });
};

export const updateOrderItemMediaSortOrder = async ({
  changedOrderItemMediaSortOrder,
  orderId,
  orderItemId,
  router,
  setShowToast
}) => {
  const { status, message } = await updateOrderItemMediaSortOrderAPI({
    orderItemMediaToSort: changedOrderItemMediaSortOrder,
    orderItemId,
    orderId
  });
  if (status) {
    router.reload();
    return;
  }
  !status && setShowToast({ show: true, status, message });
};

export const getOrderItemMediaDirectoryPath = ({ orderId, orderItemId }) =>
  `API/${environmentName}/db/orders/${orderId}/${orderItemId}/`;
